@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-background text-text text-center;
  }
}

@layer components {
  .btn {
    @apply px-4 py-2 rounded-lg font-semibold transition duration-300;
    &:disabled {
      @apply bg-gray-500 text-gray-200 cursor-not-allowed;
    }
  }

  .btn-primary {
    @apply bg-primary text-text hover:bg-opacity-80;
    &:disabled {
      @apply bg-gray-500 text-gray-200 cursor-not-allowed;
    }
  }

  .btn-secondary {
    @apply bg-secondary text-text hover:bg-opacity-80;
    &:disabled {
      @apply bg-gray-500 text-gray-200 cursor-not-allowed;
    }
  }

  .btn-accent {
    @apply bg-accent text-background hover:bg-opacity-80;
    &:disabled {
      @apply bg-gray-500 text-gray-200 cursor-not-allowed;
    }
  }

  .btn-tall {
    @apply h-12;
  }

  .container {
    @apply mx-auto px-4 py-6 bg-secondary text-text text-center rounded-lg;
  }

  .container-sm {
    @apply max-w-sm;
  }

  .container-md {
    @apply max-w-md;
  }

  .container-lg {
    @apply max-w-lg;
  }

  .container-xl {
    @apply max-w-xl;
  }

  .input {
    @apply px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent text-black;
  }
}

.lds-spinner,
.lds-spinner div,
.lds-spinner div:after {
  box-sizing: border-box;
}
.lds-spinner {
  color: currentColor;
  display: inline-block;
  position: relative;
  width: 30px; 
  height: 30px; 
}
.lds-spinner div {
  transform-origin: 15px 15px; 
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 2.4px; 
  left: 13.8px; 
  width: 2.4px;
  height: 6.6px; 
  border-radius: 20%;
  background: currentColor;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* for centering content */
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; 
}

.typing-loader {
  width: 6px; 
  height: 6px; 
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 12px 0 #fff, -12px 0 #fff;  
  position: relative;
  animation: flash 0.5s ease-out infinite alternate;
}

@keyframes flash {
  0% {
    background-color: #FFF2;
    box-shadow: 12px 0 #FFF2, -12px 0 #FFF; 
  }
  50% {
    background-color: #FFF;
    box-shadow: 12px 0 #FFF2, -12px 0 #FFF2;  
  }
  100% {
    background-color: #FFF2;
    box-shadow: 12px 0 #FFF, -12px 0 #FFF2;  
  }
}
